/* You can add global styles to this file, and also import other style files */

/* html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */

/******* Base styles *******/

html{
    height: 100%;
}

body{
    height: 100%;
    margin:0; padding: 0;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.5;
}

*{
    margin: 0;
    padding: 0;
}

a{    
    outline: none;
}

.h-100 {
    height: 100% !important;
}

.w-100{
    width: 100% !important;
}

.w-50-px {
    width: 50px !important;
}

.mw-100{
    max-width: 100%;
}

.fw-300{
    font-weight: 300;
}

.fw-400{
    font-weight: 400;
}

.fw-500{
    font-weight: 500;
}

.lh{
    line-height: 27px;
}

.light-block.mat-card{
    background: rgba(0,0,0,0.03) !important;
    box-shadow: none !important;    
}

.transition{
    transition: 0.3s;
}

.text-truncate{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden !important;
}

.text-center{
    text-align: center !important;
}

.text-right{
    text-align: right !important;
}

.text-left{
    text-align: left !important;
}

.divider{
    border-top: 1px solid rgba(204, 204, 204, 0.6);
}

.mat-icon-button .mat-icon.mat-icon-xs,
.mat-icon.mat-icon-xs{
    font-size: 14px;
    line-height: 14px;
    height: 14px;
    width: 14px;
    vertical-align: middle;
}

.mat-icon-button .mat-icon.mat-icon-sm,
.mat-icon.mat-icon-sm,
.mat-nav-list .mat-list-item .mat-list-icon.mat-icon-sm{
    font-size: 18px;
    line-height: 18px;
    height: 18px;
    width: 18px;
    vertical-align: middle;
}

.mat-icon-button .mat-icon.mat-icon-lg,
.mat-icon.mat-icon-lg{
    font-size: 36px;
    line-height: 36px;
    height: 36px;
    width: 36px;
    vertical-align: middle;
}

.mat-icon-button .mat-icon.mat-icon-xlg,
.mat-icon.mat-icon-xlg{
    font-size: 48px;
    line-height: 48px;
    height: 48px;
    width: 48px;
    vertical-align: middle;
}

.mat-icon.caret{
    width: 14px;
}

.mat-button.flex-row-button .mat-button-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mat-button-wrapper{
    pointer-events: none;
}

.mat-list.reviews .mat-list-item .mat-list-item-content{
    align-items: start;
}

.mat-step-text-label{
    text-transform: uppercase;
}

.mat-tab-label-content{
    font-size: 16px;
}

.mat-row, .mat-header-row{
    border-bottom-color: rgba(0,0,0,.12);
}

.app-dropdown .mat-menu-item{
    height: 36px;
    line-height: 36px;
    font-size: 14px;
}

.account-icon{
    margin-top: -2px;
    margin-right: 2px;
}

.categories-dropdown.mat-menu-panel{
    max-height: 350px;
}

.categories-dropdown .mat-menu-item{
    height: 24px;
    line-height: 24px;
    font-size: 14px;
}

.sub-category button{
    padding-left: 30px;
}

.sub-category .sub-category button{
    padding-left: 50px;
}

.p-0{
    padding: 0 !important;
}

.p-1{
    padding: 10px !important;
}

.p-2{
    padding: 20px !important;
}

.p-3{
    padding: 30px !important;
}

.py-1{
    padding: 10px 0 !important;
}

.py-2{
    padding: 20px 0 !important;
}

.py-3{
    padding: 30px 0 !important;
}

.py-4{
    padding: 40px 0 !important;
}

.py-5{
    padding: 50px 0 !important;
}

.px-1{
    padding: 0 10px !important;
}

.px-2{
    padding: 0 20px !important;
}

.px-3{
    padding: 0 30px !important;
}

.px-4{
    padding: 0 40px !important;
}

.px-5{
    padding: 0 50px !important;

}

.pl-1{
    padding-left: 10px !important;
}

.pl-2{
    padding-left: 20px !important;
}

.pl-3{
    padding-left: 30px !important;
}

.m-0{
    margin: 0 !important;
}

.m-1{
    margin: 10px !important;
}

.m-2{
    margin: 20px !important;
}

.m-3{
    margin: 30px !important;
}

.m-4{
    margin: 40px !important;
}

.m-5{
    margin: 50px !important;
}

.mt-1{
    margin-top: 10px !important;
}

.mt-2{
    margin-top: 20px !important;
}

.mt-3{
    margin-top: 30px !important;
}

.mt-4{
    margin-top: 40px !important;
}

.mt-5{
    margin-top: 50px !important;
}

.mb-1{
    margin-bottom: 10px !important;
}

.mb-2{
    margin-bottom: 20px !important;
}

.mb-3{
    margin-bottom: 30px !important;
}

.mb-4{
    margin-bottom: 40px !important;
}

.mb-5{
    margin-bottom: 50px !important;
}

.mr-1{
    margin-right: 10px !important;
}

.mr-2{
    margin-right: 20px !important;
}

.mr-3{
    margin-right: 30px !important;
}

.mr-4{
    margin-right: 40px !important;
}

.mr-5{
    margin-right: 50px !important;
}

.ml-1{
    margin-left: 10px !important;
}

.ml-2{
    margin-left: 20px !important;
}

.ml-3{
    margin-left: 30px !important;
}

.ml-4{
    margin-left: 40px !important;
}

.ml-5{
    margin-left: 50px !important;
}

.d-block{
    display: block;
}

.social-icon{
    width: 48px;
    height: 48px;
    cursor: pointer;
    fill: #8f8f8f;
    outline: none;
}

/******* Carousel *******/

button.swipe-arrow{
    position: absolute;
    top: 50%;
    margin-top: -22px;
    z-index: 10;
    background-image: none;
}

.swiper-pagination.white .swiper-pagination-bullet{
    background: #fff;
}

.swiper-pagination-bullet-active{
    width: 12px;
    height: 12px;
    vertical-align: -2px;
}

.swiper-button-prev:after, 
.swiper-container-rtl .swiper-button-next:after,
.swiper-button-next:after, 
.swiper-container-rtl .swiper-button-prev:after{
    content: none;
}

/******* Back to top *******/

.back-to-top{
    position: fixed;
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 999999;
    right: 20px;
    bottom: 20px;
    opacity: .5;
    color: #fff;
    background-color: rgba(0,0,0,.75);
    border-radius: 4px;    
}

.back-to-top:hover{
    opacity: 0.9;
}

/******* Mat Snack Bar *******/

.cdk-overlay-pane {
    z-index: 10000 !important;
}

.cdk-overlay-container {
    position: fixed;
    z-index: 10000 !important;
}

.mat-snack-bar-container.success {
    background: #388e3c;
    color: white !important;
}

.mat-snack-bar-container.error {
    background: #e53935;
    color: white !important;
}

.mat-snack-bar-container.warning {
    background: #e5b235;
    color: white !important;
}

.mat-snack-bar-container.information {
    background: #3f85ee;
    color: white !important;
}

.mat-snack-bar-container button  {
    background-color: transparent !important;
    color: white !important;
    border: none;
}
